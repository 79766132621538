import './App.css';
import {
  NavLink,
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';
import Home from './Pages/Home';
import Snake from './Pages/Snake';
import NotFound from './Pages/NotFound';

function App() {
  return <div className='App'>
    <BrowserRouter basename='/'>
      <div className='App-header'>
        <div className='App-nav'>
          <NavLink to='/'>Home</NavLink>
          <NavLink to='/snake'>Snake</NavLink>
        </div>
      </div>
      <div className='App-body'>
        <Routes>
          <Route path='*' element={<NotFound/>}/>
          <Route path='/' element={<Home/>}/>
          <Route path='/snake' element={<Snake/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  </div>
}

export default App;
